'use client';

import { classes } from '@/next-utils/css-utils/scss-utils';
import { IForm } from '@/react/view-models/Form';
import { msg } from '@/services/isomorphic/I18NService';
import { Nullable } from '@/type-utils';
import { observer } from 'mobx-react-lite';
import { ReactNode, SelectHTMLAttributes, useMemo } from 'react';
import { useBrandLocaleValue } from '@/react/hooks/useBrandLocaleValue';
import { useViewModel } from '../../../hooks/useViewModel';
import ITestIdentifiable from '../../traits/ITestIdentifiable';
import { Icon, IconSizeProp, IconTypes } from '../Icon';

import S from './styles.module.scss';
import { forms_requiredIndicator } from "@/lang/__generated__/ahnu/forms_requiredIndicator";

/**
 * Select option definition.
 */
export interface ISelectOption {
  /**
   * Represents a caption for the option.
   */
  label?: string;
  /**
   * Value of the option.
   */
  value: string | number;

  /**
   * Adds the 'hidden' property to the option.
   */
  hidden?: boolean;

  /**
   * Disables the option so that it appears disabled, used usually for default selections.
   */
  disabled?: boolean;
}

export interface ISelectProps<T extends IForm>
  extends SelectHTMLAttributes<HTMLSelectElement>,
    ITestIdentifiable {
  /**
   * This is a mirror for the value attribute. Inside an input mask, you cannot pass a value attribute
   * into the child component.
   */
  formModel?: T;

  /** The name of the field being used. */
  fieldName?: keyof T;

  /**
   * Allows the select to full fill the width of the parent component.
   */
  fullWidth?: boolean;
  /**
   * Represents a caption for the select.
   */
  label: string;
  /**
   * Should the label be shown or be an aria label.
   */
  showLabel?: boolean;
  /**
   * The options to be displayed in the select dropdown.
   */
  options: Array<ISelectOption>;
  /**
   * If isOptional is true then no asterisk will be added to the label.
   */
  isOptional?: boolean;
}

/**
 * Select Field represents a control that presents a menu of options.
 */
export const Select = observer(function Select<T extends IForm>(
  props: ISelectProps<T>
) {
  const {
    formModel,
    fieldName,
    id,
    label,
    showLabel = true,
    options,
    fullWidth,
    isOptional,
    datatestID,
    onChange,
    className,
    ...rest
  } = props;

  const dropdownIcon = useBrandLocaleValue<IconTypes>(
    () => ({
      default: IconTypes.CaretDown
    }),
    []
  );

  const [vm] = useViewModel({
    /**
     * Set the select field to touched so that it will be validated.
     */
    setTouch() {
      if (formModel) {
        formModel.setTouch(fieldName);
      }
    },

    /**
     * The current error of this select form element.
     * @returns A react node to be rendered.
     */
    get error(): Nullable<ReactNode> {
      if (formModel) {
        const { errors } = formModel.validateProperty(fieldName);

        // When the `key` argument is present for `validate()`
        // there can only be 1 invalid property in the array.
        // If the array is empty, there are no errors.
        const displayError = errors[0] ?? [];

        // Render a fragment containing all the errors.
        return <>{displayError && <span>{displayError}</span>}</>;
      }
      return null;
    },

    /**
     * Is the form currently valid, if not using a form model, will always be true.
     * @returns Is it valid.
     */
    get isValid(): boolean {
      if (formModel) {
        const { isValid } = formModel.validateProperty(fieldName);
        return isValid;
      }

      return true;
    }
  });

  const initialValue = formModel
    ? ((formModel as Record<string, unknown>)[fieldName as string] as
        | string
        | number
        | Array<string>
        | undefined)
    : '';

  // Get a value from the form model if available. This is so the Select's value
  // changes when its field is changed on the form model.
  const value =
    formModel &&
    (formModel[fieldName as keyof T] as string | number | Array<string>);

  const optionsJSX = useMemo(
    () =>
      options.map(({ label, value, hidden, disabled }) => (
        <option
          key={value}
          value={value}
          hidden={hidden ?? false}
          disabled={disabled ?? false}
        >
          {label ?? value}
        </option>
      )),
    [options]
  );

  return (
    <div className={classes(S.container, vm.isValid ? '' : S.error)}>
      {showLabel && (
        <label htmlFor={id}>
          {!isOptional ? msg(forms_requiredIndicator) : ''}
          {label}
        </label>
      )}
      <div className={S.selectContainer}>
        <Icon
          icon={dropdownIcon}
          className={S.icon}
          size={IconSizeProp.SizeXS}
        />
        <select
          // Spread props first so they don't override the ones specified
          // below.
          {...rest}
          id={id}
          {...{ ariaLabel: !showLabel && label }}
          className={classes(
            S.select,
            { [S.fullWidth]: Boolean(fullWidth) },
            className
          )}
          onChange={(e) => {
            if (formModel) {
              vm.setTouch();
              (formModel as Record<string, unknown>)[fieldName as string] =
                e.target.value;
            }

            // If an onChange callback was passed, call it after updating
            // the value in the form model.
            if (onChange) {
              onChange(e);
            }
          }}
          // Only include value if present.
          {...(value && { value })}
          defaultValue={initialValue}
          data-testid={datatestID}
        >
          {optionsJSX}
        </select>
      </div>
      {vm.error && <div className={S.errorMessage}>{vm.error}</div>}
    </div>
  );
});
