import { useRevalidateCartWithPush } from '@/react/hooks/useRevalidateCartWithPush';
import { writePlacedOrderToSession } from '@/react/utils/checkout-utils/writePlacedOrderToSession';
import { withPreventUnload } from '@/react/utils/dom-utils';
import type CartVM from '@/react/view-models/CartVM';
import LoggerService from '@/services/isomorphic/LoggerService';
import { PaymentInterruptedError } from '@/services/isomorphic/PaymentRequestService';
import { useCallback } from 'react';

/**
 * A hook that provides the click handler for the Apple Pay button.
 * @param cart - The cart view model which contains the order to be placed with Apple Pay.
 * @returns The click handler for the Apple Pay button.
 *
 * **Implementation Note:** This hook is not meant to be reusable. It exists primarily to encapsulate
 * the event handler logic so that it can be tested holistically, due to the constraints of the Apple Pay API.
 */
export function useApplePayClickHandler(cart: CartVM): () => Promise<void> {
  const [revalidateCartAndPush] = useRevalidateCartWithPush();

  const handlePayment = useCallback(async () => {
    /** {@link CartVM.orderWithApplePay} must not be called if the cart is not ready. */
    if (!cart.isReady) return;

    /**
     * Do **NOT** place any asynchronous code before `orderWithApplePay()`.
     * It must run synchronously until the Apple Pay Session is created, or
     * it will error.
     */

    try {
      await withPreventUnload(async () => {
        const order = await cart.orderWithApplePay();

        writePlacedOrderToSession(order);

        // revalidate the cart since it was emptied by the server after order placement
        revalidateCartAndPush(
          `/checkout/confirmation?token=${order.orderToken}`
        );
      });
    } catch (error) {
      if (error instanceof PaymentInterruptedError) {
        LoggerService.error(error);
        return;
      }
      throw error;
    }
  }, [cart, revalidateCartAndPush]);

  return handlePayment;
}
