import { useRouter } from 'next/navigation';
import { useCallback, useTransition } from 'react';
import { useRevalidateCart } from '../providers/global/RevalidateCartContext';

/** The options that can be passed to a `router.push()`. */
type NavigationOptions = Parameters<ReturnType<typeof useRouter>['push']>[1];
/** A `push`-like function that you'd get from {@link useRouter}. */
// This definition doesn't use `useRouter` to avoid a strange Cmd-click behavior in VSCode.
type PushNavigationFn = (href: string, options?: NavigationOptions) => void;

/**
 * A utility hook that coordinates the revalidation of the cart with a push
 * navigation in a React transition. This allows us to hide the UI updates
 * that occur from revalidating the cart until the navigation is complete.
 *
 * @returns A tuple containing a `push`-like navigation function and a boolean
 * indicating whether the navigation is pending.
 */
export function useRevalidateCartWithPush(): [PushNavigationFn, boolean] {
  const revalidateCart = useRevalidateCart();
  const router = useRouter();
  const [isRoutePending, startRouteTransition] = useTransition();

  const action = useCallback<PushNavigationFn>(
    (href, options) => {
      startRouteTransition(() => {
        revalidateCart();
        router.push(href, options);
      });
    },
    [revalidateCart, router]
  );

  return [action, isRoutePending];
}
